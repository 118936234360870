<template>
  <div class="row mx-0 justify-content-center page_top">
    <div class="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-7 col-xxl-6 p-0 my-4 card_item">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 p-0">
          <ContentWithBackground class="rounded">
            <div class="row mx-0 justify-content-center">
              <div class="col-12 text-center heading">
                This calculator will assist you to calculate your zakaat.
              </div>
              <div class="col-auto medium thesteps mt-3">
                <div class="mb-2 bold"><u>Steps:</u></div>
                1) Give this calculation a name<br>
                2) Select your zakaat date (defaulted to today)<br>
                3) Add all your assets like gold, money, accounts, etc.<br>
                4) Add liabilities like rent, loans, etc.<br>
              </div>
              <div class="col-12 col-sm-11 col-md-11 col-lg-10 col-xl-9 text-center mt-4 font14 medium belowsteps">
                <div class="mb-2">
                  The calculator will automatically use your information and the resource prices on the selected date (like gold, silver and currencies) to provide you with the amount of zakaat that you need to pay.
                </div>
                <div>
                  The following year, you will be able to more easily calculate your zakaat. For example, the value of all your resources (like Jewelry, currency and coins) will automatically be calculated the following year using the updated resource values on your next zakaat date.
                </div>
              </div>
              <div class="col-12 mt-3 text-center disclaimer belowsteps font12">
                *Disclaimer: We do not store user financial details inputted in the Zakaat calculator. This data can only be accessed by users themselves.
              </div>
            </div>
          </ContentWithBackground>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ContentWithBackground: defineAsyncComponent(() => import('@/components/styling/ContentWithBackground.vue'))
  },
  name: 'ZakaahTheProcessSteps'
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}

.belowsteps {
  color: var(--normal-color);
}
@media (min-width: 992px) {
  .heading {
    font-size: 1.5rem;
  }

}
</style>
